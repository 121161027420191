import Storage from "services/system/storage/Storage";
import {StorageKeys} from "services/system/storage/StorageKeys";

/**
 * We can't store Freshdesk request state in Context API,
 * due to the Freshdesk state will be gone after SSON page redirection.
 * Therefore, saving Fresdesk request state into the local storage is necessary.
 */

/** Ask SAML Request for Single Sign-On */
export function askSamlRequestForSSON() {
	Storage.write(StorageKeys.FRESHDESK_DID_ASK_SSON_SAML_REQUEST, "true");
}

/**
 * Get the status of asking for SAML Request to perform Single Sign-On.
 * @returns True if User did ask for SAML Request, False otherwise.
 */
export function didAskSamlRequestForSSON(): boolean {
	return Storage.readFromLocalStorage<boolean>(StorageKeys.FRESHDESK_DID_ASK_SSON_SAML_REQUEST);
}

/** Reset Freshdesk Single Sign-On requests state */
export function resetSSONRequestState() {
	Storage.write(StorageKeys.FRESHDESK_DID_ASK_SSON_SAML_REQUEST, null);
}
